<template>
    <!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10326')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
    <!-- // nav -->
	<div id="container" class="container">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub mypage">
			<!-- content -->
			<section class="content">
				<div class="row">
					<h4 class="frms__subtitle" style="margin-bottom:14px">
						{{t('10334')}}
					</h4>
					<div class="frms__item">
						<h4 class="frms__subtitle"> {{t('10335')}} <span class="dot dot--red"></span></h4>
						<div class="frms__wrap">
							<div class="slt">
								<input 
									type="text" 
									class="ipt-frm ipt-slt"  
									readonly 
									:placeholder="t('10832')"  
									style="background:none" 
									ref="inquiryTypeInput"
									:value="inquiryTypeName" 
									@click="onClickShowInquiryType"><!-- 재번역 체크 -->
								<div class="frms__btns">
									<button type="button" class="btn-frmreset" @click="onClickShowInquiryType">
										<i class="icon icon-down"></i>
									</button>
								</div><!-- [D] 선택시 input에 입력되고, icon은 제거 -->
								<ul class="slt__list" :class="{'slt__list-show': isSelectInquiryType}">
									<li v-for="item in inquiryTypeData" :key="item.id" @click="onClickInquiryType(item)">{{item.name}}</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="frms__item">
						<h4 class="frms__subtitle"> {{t('10336')}} <span class="dot dot--red"></span></h4>
						<div class="frms__wrap">
							<input type="text" class="ipt-frm ipt-slt"  :placeholder="t('10833')"  v-model="inquiryTitle"><!-- 재번역 체크 -->
						</div>
					</div>
					<div class="frms__item">
						<div class="review__text" style="margin-right:0">
							<textarea class="ta ta--transparent" :placeholder="t('10826')"  style="height:170px;" maxlength=200 v-model="inquiryDescription"></textarea><!-- 재번역 체크 -->
							<div class="review__number">
								<span>{{inquiryDescription.length}}</span> / 200
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
    <div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<button type="button" class="btn" :class="enableWriteInquiry ? 'btn-confirm' : 'btn-disabled'" @click="onClickWriteInquiry">{{t('10337')}}</button>
		</div>
	</div>
</template>

<script>
import { useStore } from "vuex"
import { computed, ref, onMounted } from "vue"
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	setup: function() {
		const router = useRouter();
		const route = useRoute();
		const store = useStore();

		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const isSelectInquiryType = ref(false);

    const seq = Number(route.params.seq === "" ? -1 : route.params.seq);

		const mmy0112 = computed(() => store.state.mmy01.mmy0112);
		const currentInquiry = computed(() => mmy0112.value.find(item => item.SEQ === seq));

		const inquiryTypeServerData = {
			"예약문의" : "01",
			"결제관련" : "02",
			"포인트문의" : "03",
			"회원정보" : "04",
			"취소관련" : "05",
			"추천인" : "06",
			"단체관련" : "07",
			"기타문의" : "99",
		};
		const inquiryTypeData = [
			{id : "01", name : "예약"},
			{id : "02", name : "결제"},
			{id : "03", name : "포인트"},
			{id : "05", name : "취소"},
			{id : "07", name : "단체"},
			{id : "04", name : "회원정보"},
			{id : "06", name : "추천인"},
			{id : "99", name : "기타"},
		];

		const inquiryTypeInput = ref(null);
		const inquiryType = ref(0);
		const inquiryTypeName = computed(()=> inquiryTypeData.find(item => item.id === inquiryType.value)?.name ?? "");
		const inquiryTitle = ref("");
		const inquiryDescription = ref("");

		const enableWriteInquiry = computed(() => {
			return inquiryType.value !== 0 && inquiryTitle.value !== "" && inquiryDescription.value.length >= 10;
		});

		onMounted(()=>{
			if(isLogin.value && seq !== -1 && currentInquiry.value === undefined){
				store.dispatch("mmy01/fetchMmy0112", {
					proc_cd: "01",
					mem_id: userData.value.mem_token
				}).then((data)=>{
					const findInquiry = data.Res_data.find(item => item.SEQ === seq);
					inquiryType.value = inquiryTypeServerData[findInquiry.QUES_CD];
					inquiryTitle.value = findInquiry.QUES_TITLE;
					inquiryDescription.value = findInquiry.QUES_DESC;
				});
			}
			else if(isLogin.value && currentInquiry.value !== undefined){
				inquiryType.value = inquiryTypeServerData[currentInquiry.value.QUES_CD];
				inquiryTitle.value = currentInquiry.value.QUES_TITLE;
				inquiryDescription.value = currentInquiry.value.QUES_DESC;
			}
		});

  	const onClickWriteInquiry = () => {
			if(enableWriteInquiry.value) {
				store.dispatch("mmy01/fetchMmy01121", {
						proc_cd: seq === -1 ? "01" : "02",
						mem_id: userData.value.mem_token,
						ques_cd: inquiryType.value,
						ques_title: inquiryTitle.value,
						ques_desc: inquiryDescription.value,
						seq: seq,
						ques_source: isLogin.value ? "01" : "02"
					}).then(() => {
					alert("문의가 등록되었습니다.");
					router.replace("/mypage/inquiry/list");
				});
			}
  	};

		const onClickShowInquiryType = () => {
			if(!isSelectInquiryType.value){
				inquiryTypeInput.value.focus();
			}
			isSelectInquiryType.value = !isSelectInquiryType.value;
		};

		const onClickInquiryType = (type) => {
			inquiryType.value = type.id;
			isSelectInquiryType.value = false;
		};

		const goBack = () => {
			router.go(-1);
		};
		const { t }= useI18n() //번역필수 모듈

		// watchEffect(() => {
		// 	if(currentInquiry.value !== undefined){
		// 		inquiryType.value = currentInquiry.value.QUES_DESC;
		// 		inquiryTitle.value = currentInquiry.value.QUES_TITLE;
		// 		inquiryDescription.value = currentInquiry.value.QUES_DESC;
		// 	}
		// });

		return {
			goBack,
      currentInquiry,
			isLogin,
      onClickWriteInquiry,
			inquiryTypeData,
			inquiryType,
			inquiryTypeName,
			inquiryTitle,
			inquiryDescription,
			enableWriteInquiry,
			isSelectInquiryType,
			onClickShowInquiryType,
			onClickInquiryType,
			inquiryTypeInput,
			t,
			i18n
		}
	}
}
</script>